@import '../../../styles/var';

.inNumbers {
    position: relative;
    z-index: 10;
    .title {

    }
    .cards {
        display: flex;
        flex-direction: column;
        gap: 300px;
        margin-top: 217px;
        @media(max-width: $md1+px) {
            gap: 250px;
            margin-top: 200px;
        }
        @media(max-width: $md4+px) {
            margin-top: 120px;
        }
        @media(max-width: 600px) {
            gap: 150px;
        }
        @media(max-width: $md6+px) {
            gap: 130px;
        }
        .card {
            position: relative;
            width: 621px;
            @media(max-width: $md4+px) {
                width: 100%;
                max-width: 660px;
                margin: 0 auto!important;
            }
            &:nth-child(1) {
                .cardImage { 
                    bottom: -73px;
                    @media(max-width: $md1+px) {
                        bottom: -50px;
                    }
                    @media(max-width: 600px) {
                        bottom: 0;
                    }
                    @media(max-width: $md6+px) {
                        bottom: -21px;
                    }
                }
            }
            &:nth-child(2) {
                margin-left: auto;
                @media(max-width: $md6+px) {
                    padding-top: 50px;
                }
                .cardImage {
                    bottom: 3px;   
                }
            }
            &:nth-child(3) {
                .cardImage {
                    bottom: 13px;
                    @media(max-width: 600px) {
                        bottom: -5px;
                    }
                    @media(max-width: $md6+px) {
                        bottom: -34px;
                    }
                }
            }
            &Title {
                position: relative;
                z-index: 10;
                color: #B1FE4B;
                font-family: $furore;
                font-size: 50px;
                font-weight: 400;
                line-height: 100%;
                letter-spacing: 2.5px;
                text-transform: uppercase;
                @media(max-width: $md6+px) {
                    font-size: 18px;
                }
            }
            &Text {
                position: relative;
                z-index: 10;
                color: #FFF;
                font-family: $roboto;
                font-size: 30px;
                font-weight: 400;
                line-height: 150%;
                letter-spacing: 1.5px;
                text-transform: capitalize;
                margin: 15px 0 30px 0;
                @media(max-width: $md4+px) {
                    font-size: 28px;
                    margin-bottom: 20px;
                }
                @media(max-width: $md6+px) {
                    font-size: 14px;
                    letter-spacing: 0.7px;
                }
            }
            &Line {
                width: 100%;
                position: relative;
                z-index: 3;
                height: 3px;
                background: rgba(255, 255, 255, 0.50);
                border-radius: 5px;
            }
            &Image {
                position: absolute;
                right: 0;
                z-index: 4;
                height: 362px;
                @media(max-width: $md1+px) {
                    height: 320px;
                }
                @media(max-width: $md4+px) {
                    height: 273px;
                }
                @media(max-width: 600px) {
                    height: 200px;
                }
                @media(max-width: $md6+px) {
                    height: 170px;
                }
            }
        }
    }
}
@import "./_var.scss";

body,
html {
    background: #000;
    color: #fff;
    &.lock {
        overflow: hidden;
    }
    &.lock {
        overflow: hidden;
    }
    ::-webkit-scrollbar {
        width: 5px;
    }
    ::-webkit-scrollbar-track {
        background-color: #000;
    }
    ::-webkit-scrollbar-thumb {
        background: #B1FE4B;
    }
}

.font-100 {
    color: #B1FE4B;
    text-shadow: 0px 0px 56px #B1FE4B, 0px 0px 150px #B1FE4B;
    font-family: $frm;
    font-size: 100px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: 17px;
    margin-left: 17px;
    @media(max-width: $md4+px) {
        font-size: 80px;
        text-shadow: 0px 0px 40px #B1FE4B, 0px 0px 80px #B1FE4B;
        letter-spacing: 13px;
        margin-left: 13px;
    }
    @media(max-width: $md6+px) {
        font-size: 50px;
        text-shadow: 0px 0px 30px #B1FE4B, 0px 0px 60px #B1FE4B;
        letter-spacing: 8px;
        margin-left: 8px;
    }
}

.font-50 {
    text-align: center;
    color: #B1FE4B;
    text-shadow: 0px 0px 30px #B1FE4B, 0px 0px 100px #B1FE4B;
    font-family: $frm;
    font-size: 50px;
    font-weight: 500;
    line-height: 150%;
    letter-spacing: 8px;
    text-transform: uppercase;
    @media(max-width: $md4+px) {
        font-size: 35px;
        letter-spacing: 6px;
        text-shadow: 0px 0px 30px #B1FE4B, 0px 0px 80px #B1FE4B;
    }
    @media(max-width: $md6+px) {
        font-size: 25px;
        letter-spacing: 4px;
        text-shadow: 0px 0px 20px #B1FE4B, 0px 0px 50px #B1FE4B;
    }
}

.font-40 {
    text-align: center;
    color: #B1FE4B;
    text-shadow: 0px 0px 30px #B1FE4B, 0px 0px 100px #B1FE4B;
    font-family: $frm;
    font-size: 40px;
    font-weight: 400;
    line-height: 150%;
    letter-spacing: 7px;
    text-transform: uppercase;
    @media(max-width: $md4+px) {
        font-size: 35px;
        letter-spacing: 6px;
        text-shadow: 0px 0px 30px #B1FE4B, 0px 0px 80px #B1FE4B;
    }
    @media(max-width: $md6+px) {
        font-size: 20px;
        letter-spacing: 3px;
        text-shadow: 0px 0px 20px #B1FE4B, 0px 0px 50px #B1FE4B;
    }
}

.font-20 {
    color: #E7E7E7;
    text-shadow: 0px 0px 2px rgba(241, 237, 238, 0.40);
    font-family: $furore;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 150%;
    @media(max-width: $md4+px) {
        font-size: 18px;
    }
    @media(max-width: $md6+px) {
        font-size: 15px;
    }
}

.font-17 {
    color: #E7E7E7;
    font-family: $roboto;
    font-size: 17px;
    font-weight: 300;
    line-height: 150%;
    letter-spacing: 2.7px;
    @media(max-width: $md4+px) {
        font-size: 16px;
    }
    @media(max-width: $md6+px) {
        font-size: 14px;
    }
}

.font- {
    
    @media(max-width: $md4+px) {
    
    }
    @media(max-width: $md6+px) {
    
    }
}

.font- {
    
    @media(max-width: $md4+px) {
    
    }
    @media(max-width: $md6+px) {
    
    }
}

.font- {
    
    @media(max-width: $md4+px) {
    
    }
    @media(max-width: $md6+px) {
    
    }
}

.font- {
    
    @media(max-width: $md4+px) {
    
    }
    @media(max-width: $md6+px) {
    
    }
}

.font- {
    
    @media(max-width: $md4+px) {
    
    }
    @media(max-width: $md6+px) {
    
    }
}

.home-bg {
    position: absolute;
    z-index: 0;
    top: 0;
    width: 1520px;
    left: 50%;
    transform: translateX(-50%);
    pointer-events: none;
    @media(max-width: $md4+px) {
        width: 1200px;
    }
    @media(max-width: $md6+px) {
        width: 1000px;
    }
    &::before, &::after {
        content: '';
        position: absolute;
        top: 0;
        width: 100px;
        height: 100%;
    }
    &::before {
        left: -1px;
        background: linear-gradient(90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    &::after {
        right: -1px;
        background: linear-gradient(-90deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0) 100%);
    }
    img {
        width: 100%;
    }
}

.m-content {
    margin-top: 100px;
    @media(max-width: $md1+px) {
        margin-top: 80px;
    }
    @media(max-width: $md4+px) {
        margin-top: 70px;
    }
}

.mt {
    margin-top: 250px;
    @media (max-width: $md4+px) {
        margin-top: 200px;
    }
    @media (max-width: $md6+px) {
        margin-top: 120px;
    }
}

.ovf-hidden {
    overflow: hidden;
    position: relative;
}

.no-select {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

.Dropdown-control {
    font-family: $furore;
    font-style: normal;
    font-weight: 400;
    font-size: 17px;
    background-color: transparent;
    border: none;
    color: #fff;
    text-transform: uppercase;
    padding-right: 25px;
    cursor: pointer;
    &:hover {
        box-shadow: none;
    }
}
.Dropdown-menu {
    font-family: $furore;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    max-height: 250px!important;
}
.Dropdown-arrow {
    border-color: #fff transparent transparent;
}
.is-open .Dropdown-arrow {
    border-color: transparent transparent #fff;
}

.flex {
    display: flex;
}
.align-items-center {
    align-items: center;
}
.gap-srch {
    gap: 10px;
}
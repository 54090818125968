@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300;400&display=swap');

@font-face {
    font-family: "Furore";
    src: url("../assets/fonts/Furore.otf") format("truetype");
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: "Frm";
    src: url("../assets/fonts/FRM3216x16.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
}
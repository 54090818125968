@import '../../../styles/var';

$animationSpeed: 5s;

$pcHeight: 280px;
$plateHeight: 230px;
$mobileHeight: 180px;

.bannerWrap {
    position: relative;
    .marquee {
        position: absolute;
        z-index: 10;
        width: 100%;
        height: 80px;
        left: 0;
        bottom: 0;
        background: #B1FE4B;
        @media(max-width: $md4+px) {
            height: 60px;
        }
    }
    .dragon {
        position: absolute;
        right: 0;
        top: 170px;
        width: 358px;
        @media(max-width: $md1+px) {
            top: 80px;
            width: 310px;
        }
        @media(max-width: $md4+px) {
            top: 260px;
            width: 250px;
        }
        @media(max-width: $md6+px) {
            width: 160px;
            top: 220px;
            right: -20px;
        }
    }
}


.banner {
    position: relative;
    z-index: 10;
    padding-top: 223px;
    height: 100vh;
    min-height: 890px;
    max-height: 1100px;
    @media(max-width: $md1+px) {
        padding-top: 160px;
        min-height: 800px;
        max-height: 1000px;
    }
    @media(max-width: $md3+px) {
        padding-top: 200px;
        height: initial;
        min-height: initial;
        max-height: initial;
        padding-bottom: 140px;
    }
    @media(max-width: $md6+px) {
        padding-top: 125px;
        padding-bottom: 100px;
    }
    .content {
        position: relative;
        z-index: 10;
        text-align: center;
        .title {

        }
        .subtitle {
            margin: 30px 0 50px 0;
            @media(max-width: $md4+px) {
            
            }
            @media(max-width: $md6+px) {
            
            }
        }
        .btn {

        }
    }
    
    .slider {
        box-shadow: 0 10px 20px -5px rgba(0, 0, 0, .125);
        height: 280px;
        margin: auto;
        overflow: hidden;
        position: relative;
        width: 119px;
        @media(max-width: $md1+px) {
            height: $plateHeight;
            width: 104px;
        }
        @media(max-width: $md6+px) {
            height: $mobileHeight;
            width: 80px;
        }
        .slideTrack {
            animation: carousel-pc $animationSpeed linear infinite;
            display: flex;
            width: 100%;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @media(max-width: $md1+px) {
                animation: carousel-plate $animationSpeed linear infinite;
            }
            @media(max-width: $md6+px) {
                animation: carousel-mobile $animationSpeed linear infinite;
            }
        }
        
        .slide {
            height: $pcHeight;
            width: 100%;
            padding-top: 52px;
            @media(max-width: $md1+px) {
                height: $plateHeight;
                padding-top: 50px;
            }
            @media(max-width: $md6+px) {
                height: $mobileHeight;
                padding-top: 40px;
            }
            img {
                width: 100%;
            }
        }
    }
}


@keyframes carousel-pc {
    0% { transform: translateY(0); }
    30% { transform: translateY(calc(-1*$pcHeight * 6))}
    35% { transform: translateY(calc(-1*$pcHeight * 7))}
    45% { transform: translateY(calc(-1*$pcHeight * 7))}

    85% { transform: translateY(calc(-1*$pcHeight * 15))}
    90% { transform: translateY(calc(-1*$pcHeight * 16))}
    100% { transform: translateY(calc(-1*$pcHeight * 16))}
}
@keyframes carousel-plate {
    0% { transform: translateY(0); }
    30% { transform: translateY(calc(-1*$plateHeight * 6))}
    35% { transform: translateY(calc(-1*$plateHeight * 7))}
    45% { transform: translateY(calc(-1*$plateHeight * 7))}

    85% { transform: translateY(calc(-1*$plateHeight * 15))}
    90% { transform: translateY(calc(-1*$plateHeight * 16))}
    100% { transform: translateY(calc(-1*$plateHeight * 16))}
}
@keyframes carousel-mobile {
    0% { transform: translateY(0); }
    30% { transform: translateY(calc(-1*$mobileHeight * 6))}
    35% { transform: translateY(calc(-1*$mobileHeight * 7))}
    45% { transform: translateY(calc(-1*$mobileHeight * 7))}

    85% { transform: translateY(calc(-1*$mobileHeight * 15))}
    90% { transform: translateY(calc(-1*$mobileHeight * 16))}
    100% { transform: translateY(calc(-1*$mobileHeight * 16))}
}
@import '../../../styles/var';

.rules {
    position: relative;
    .title {
        max-width: 655px;
        margin: 0 auto;
    }
    .cards {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        justify-content: space-between;
        gap: 65px 60px;
        @media (max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
        }
        @media (max-width: $md6+px) {
            gap: 50px;
        }
        .card {
            &Index {
                color: #B1FE4B;
                font-family: $furore;
                font-size: 50px;
                font-weight: 400;
                line-height: 100%;
                text-transform: uppercase;
                @media (max-width: $md4+px) {
                    font-size: 45px;
                }
                @media (max-width: $md6+px) {
                    font-size: 35px;
                }
            }
            &Line {
                width: 100%;
                height: 3px;
                background: rgba(255, 255, 255, 0.50);
                margin: 15px 0 20px 0;
            }
        }
    }
}
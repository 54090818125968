@import '../../../styles/var';

.advantages {
    position: relative;
    z-index: 10;
    .title {

    }
    .cards {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(2, 1fr);
        gap: 40px;
        @media(max-width: $md1+px) {
            gap: 30px;
        }
        @media(max-width: $md3+px) {
            gap: 20px;
        }
        @media(max-width: $md4+px) {
            grid-template-columns: repeat(2, 1fr);
            grid-template-rows: repeat(3, 1fr);
            gap: 30px;
        }
        @media(max-width: 700px) {
            grid-template-columns: repeat(1, 1fr);
            grid-template-rows: repeat(6, 1fr);
        }
        .card {
            position: relative;
            padding: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 20px;
            @media(max-width: $md3+px) {
                padding: 30px;
            }   
            @media(max-width: $md4+px) {
                padding: 40px;
            }
            @media(max-width: $md6+px) {
                padding: 30px;
            }
            &Text {
                position: relative;
                z-index: 10;
                text-align: center;
            }
            .cardBoxShadow {
                position: absolute;
                z-index: 3;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                opacity: 0.5;
                box-shadow: 0px -3px 3px 1px rgba(255, 245, 221, 0.10) inset, 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 4.75px 0.25px -2.5px #FBFBFB inset, 1.25px 1.5px 0px 0px rgba(0, 0, 0, 0.75) inset, 3px 5px 2px -4.75px #FFF inset, 0px 0px 0.25px 1.25px #262524 inset;
            }
            &::before {
                content: '';
                z-index: 1;
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                border-radius: 20px;
                border: 0.5px solid #918F90;
                opacity: 0.5;
                background: linear-gradient(180deg, #1D1B1C 0%, #191718 81.19%, #252120 96.35%);
            }
            &:nth-child(4), &:nth-child(5) {
                &::after {
                    content: '';
                    position: absolute;
                    z-index: 2;
                    left: 0;
                    bottom: 0;
                    width: 100%;
                    height: 100%;
                    background-size: contain;
                    background-position: bottom center;
                    background-repeat: no-repeat;
                }
            }
            &:nth-child(4) {
                &::after {
                    background-image: url('../../../assets/img/HomePage/Advantages/card_bg_04.png');
                }
            }
            &:nth-child(5) {
                &::after {
                    background-image: url('../../../assets/img/HomePage/Advantages/card_bg_05.png');
                }
            }
        }
    }
    .btn {
        text-align: center;
        margin-top: 80px;
        @media(max-width: $md6+px) {
            margin-top: 40px;
        }
    }
}
@import "../../../styles/_var.scss";

.header {
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    width: 100%;
    transition: all 0.3s;
    background: rgba($color: #000, $alpha: 0.1);
    backdrop-filter: blur(10px);
    @media (max-width: $md3+px) {
        height: 73px;
    }
    @media (max-width: $md6+px) {
        height: 69px;
    }
    &::before {
        content: "";
        background: #000;
        position: absolute;
        width: 100%;
        height: 0px;
        opacity: 0;
        transition: all 0.3s;
        z-index: -1;
    }
    &Active {
        height: 100%;
        &::before {
            opacity: 1;
            height: 100%;
        }
        .langs {
            display: none;
        }
        path {
            fill: white;
        }
    }
    .mobileMenu {
        @media (min-width: 1221px) {
            display: none;
        }
        opacity: 0;
        padding-top: 40px;
        pointer-events: none;
        transform: translateY(-400px);
        height: 0;
        .mobileContainer {
            padding: 0 40px;
            width: 100%;
            @media (max-width: 600px) {
                padding: 0 20px;
            }
        }
        .aLink {
            display: block;
            text-align: center;
            color: #fff;
            font-family: $furore;
            font-style: normal;
            font-weight: 300;
            font-size: 24px;
            line-height: 140%;
            width: 90%;
            margin: 0 auto 15px auto;
            @media (max-width: $md6+px) {
                // text-align: left;
                width: 80%;
                // margin-left: 0;
            }
        }
        .linkActive {
            color: #B1FE4B;
        }
        .btn {
            justify-content: center;
            display: flex;
            margin-top: 30px;
            @media (max-width: 600px) {
                // justify-content: flex-start;
            }
            a {
                @media (min-width: 1221px) {
                    display: none;
                }
            }
        }
        &Active {
            display: flex;
            transition: transform 0.5s;
            transform: translateY(0px);
            opacity: 1;
            pointer-events: visible;
        }
    }
    .headerRow {
        display: grid;
        grid-template-columns: 82px 1fr auto;
        gap: 60px;
        align-items: center;
        padding: 10px 0;
        @media (max-width: $md3+px) {
            display: flex;
            justify-content: space-between;
        }
        @media (max-width: $md6+px) {
            padding: 12px 0;
        }
        &Logo {
            @media (max-width: $md3+px) {
                width: 70px;
            }
            @media (max-width: $md6+px) {
                width: 60px;
            }
            a {
                display: block;
                height: 100%;
                width: 100%;
            }
            img {
                width: 100%;
                height: auto;
            }
        }
        &Links {
            @media (max-width: $md3+px) {
                display: none;
            }
            position: relative;
            display: flex;
            justify-content: flex-end;

            gap: 50px;
            font-family: $furore;
            font-weight: 400;
            font-size: 17px;
            line-height: 130%;
            @media (max-width: $md2+px) {
                font-size: 14px;
                line-height: 21px;
                gap: 40px;
            }
            a {
                color: #fff;
                text-decoration: none;
                transition: all .1s;
                &:hover {
                    color: #7bb431;
                }
            }
            .linkActive {
                position: relative;
                color: #B1FE4B!important;
            }
        }
        &Right {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 45px;
            .burger {
                @media (min-width: 1221px) {
                    display: none;
                }
                cursor: pointer;
                display: flex;
                position: relative;
                width: 24px;
                height: 20px;
                transition: all 0.3s;
                span {
                    transition: all 0.3s;
                    position: absolute;
                    width: 100%;
                    left: 0;
                    height: 2px;
                    background: #B1FE4B;
                    &:nth-child(1) {
                        top: 0;
                    }
                    &:nth-child(2) {
                        top: calc(50% - 1px);
                    }
                    &:nth-child(3) {
                        bottom: 0;
                    }
                }
                &Active {
                    span {
                        &:nth-child(1) {
                            transform: rotate(405deg) translateY(13px);
                            left: 7px;
                        }
                        &:nth-child(2) {
                            opacity: 0;
                        }
                        &:nth-child(3) {
                            transform: rotate(-405deg) translateY(-13px);
                            left: 7px;
                        }
                    }
                }
            }
        }
    }
}

.login {
    position: relative;
    display: inline-flex;
    text-align: center;
    justify-content: center;
    transition: all 0.3s;
    cursor: pointer;
    transition: box-shadow 0.3s, background 0.4s;
    min-width: 125px;
    padding: 10px 20px;
    border-radius: 74px;
    border: 0.3px solid #918F90;
    font-family: $furore;
    font-size: 17px;
    font-weight: 400;
    line-height: normal;
    background: #1D1B1C;
    box-shadow: 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 0px 15px 15px #1D1B1C inset;
    color: #F1EDEE;
    &:hover {
        background: #B1FE4B;
        box-shadow: 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset;
        color: #000;
    }
    @media(max-width: $md3+px) {
        padding: 15px 20px;
        min-width: 188px;
    }
    @media(max-width: $md4+px) {
        font-size: 18px;
        padding: 18px;
    }
    @media (max-width: $md6+px) {
        font-size: 15px;
        min-width: 130px;
    }
    span {
        width: 100%;
    }
    &Desc {
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    &Mob {
        display: none;
        @media(max-width: $md3+px) {
            display: inline-flex;
        }
    }
}
@import '../../../styles/var';

.whyUs {
    position: relative;
    z-index: 10;
    .title {
        margin-bottom: -26px;
        @media(max-width: $md4+px) {
            margin-bottom: -24px;
        }
        @media(max-width: $md6+px) {
            margin-bottom: -15px;
        }
    }
    .tabs {
        display: flex;
        flex-direction: column;
        .tab {
            position: relative;
            width: 100%;
            border-bottom: 2px solid #FFF;
            cursor: pointer;
            &Active {
                .tabContent {
                    display: block;
                }
                .tabHeaderStatus {
                    path {
                        fill: #B1FE4B!important;
                    }
                }
            }
            &Header {
                display: grid;
                grid-template-columns: 45px 1fr 45px;
                align-items: center;
                gap: 30px;
                padding: 26px 0 26px 0;
                @media(max-width: $md4+px) {
                    padding: 24px 0 24px 0;
                }
                @media(max-width: $md6+px) {
                    padding: 15px 0 15px 0;
                    grid-template-columns: 15px 1fr;
                    gap: 25px;
                }
                &Index {
                    text-align: center;
                    color: #B1FE4B;
                    font-family: $furore;
                    font-size: 14px;
                    font-weight: 400;
                    letter-spacing: 2px;
                    @media(max-width: $md6+px) {
                        font-size: 10px;
                        letter-spacing: 2px;
                    }
                }
                &Title {
                    color: #E7E7E7;
                    font-family: $furore;
                    font-size: 25px;
                    font-weight: 400;
                    line-height: 150%;
                    letter-spacing: 4px;
                    @media(max-width: $md4+px) {
                        font-size: 18px;
                        letter-spacing: 3px;
                    }
                    @media(max-width: $md6+px) {
                        font-size: 14px;
                        letter-spacing: 2px;
                    }
                }
                &Status {
                    @media(max-width: $md6+px) {
                        display: none;
                    }
                    svg {
                        width: 45px;
                        @media(max-width: $md4+px) {
                            width: 20px;
                        }
                        path {
                            transition: fill .3s;
                        }
                    }
                }
            }
            &Content {
                display: none;
                padding-bottom: 18px;
                margin-left: 75px;
                max-width: 750px;
                @media(max-width: $md6+px) {
                    margin-left: 40px;
                }
            }
        }
    }
}
@import "../../../styles/var";

.button {
    position: relative;
    display: inline-flex;
    text-align: center;
    transition: all 0.3s;
    cursor: pointer;

    transition: box-shadow 0.3s, background 0.4s;

    min-width: 233px;
    padding: 22px;
    border-radius: 74px;
    border: 0.3px solid #918F90;
    background: #B1FE4B;
    box-shadow: 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset;

    color: #000;
    font-family: $furore;
    font-size: 20px;
    font-weight: 400;
    line-height: normal;

    @media(max-width: $md4+px) {
        font-size: 18px;
        padding: 18px;
        min-width: 188px;
    }
    @media (max-width: $md6+px) {
        font-size: 15px;
        padding: 15px 20px;
        min-width: 130px;
    }
    &:hover{
        background: #1D1B1C;
        box-shadow: 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 0px 15px 15px #1D1B1C inset;
        color: #F1EDEE;
    }
    span {
        width: 100%;
    }

    &.dark {
        background: #1D1B1C;
        box-shadow: 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset, 0px 0px 15px 15px #1D1B1C inset;
        color: #F1EDEE;
        &:hover {
            background: #B1FE4B;
            box-shadow: 2.5px -2px 3px 0px rgba(124, 108, 94, 0.75) inset, 0px -3px 1px 0px rgba(0, 0, 0, 0.50) inset, 1px 1px 3px 3px #1A1818 inset;
            color: #000;
        }
    }
}

@import '../../../styles/var';

.exchangeWrap {
    position: relative;
    z-index: 10;
    padding-top: 160px;
    width: 100%;
    margin: 0 auto;
    @media(max-width: $md4+px) {
        padding-top: 140px;
    }
    @media(max-width: $md6+px) {
        padding-top: 120px;
    }
}

.exchange {
    position: relative;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    // background: #000;
    backdrop-filter: blur(4px);
    gap: 8px;
    @media (max-width: 900px) {
        display: flex;
        flex-direction: column;
        gap: 20px;
    }
    &::before {
        pointer-events: none;
        z-index: -1;
        content: '';
        position: absolute;
        left: -1px;
        top: -1px;
        width: calc(100% + 2px);
        height: calc(100% + 2px);
        // border: 1px solid rgba($color: #fff, $alpha: .1);
    }
    .col {
        position: relative;
        @media (max-width: 900px) {
            .filterInput, .colList, .searchIcon {
                display: none;
            }
            .mobColTitle, .mobColList {
                display: block!important;
            }
        }
        .mobColTitle {
            display: none;
            color: #E7E7E7;
            font-family: $roboto;
            font-size: 20px;
            font-weight: 400;
            line-height: 150%;
            letter-spacing: 2px;
            @media(max-width: $md6+px) {
                letter-spacing: 1px;
                font-size: 18px;
            }
        }
        .mobColList {
            display: none;
        }
        &Send {

        }
        &Receive {

        }
        &Form {
            .colHeader {
                @media (max-width: 900px) {
                    margin-bottom: 6px;
                }
            }
            .formWrap {
                display: flex;
                flex-direction: column;
                gap: 30px;
                &.blur5 {
                    filter: blur(5px);
                    pointer-events: none;
                }
                .rate {
                    b {
                        font-weight: 400;
                    }
                }
                .form {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                    &Currency {
                        display: flex;
                        align-items: center;
                        gap: 12px;
                    }
                    .inputWrap {
                        .label {
                            font-size: 14px;
                            line-height: 140%;
                        }
                        input {
                            margin-top: 4px;
                            width: 100%;
                            border-radius: 10px;
                            border: 1px solid #383838;
                            height: 50px;
                            padding: 0 10px;
                            background: transparent;
                            color: #E7E7E7;
                            font-size: inherit;
                            font-weight: inherit;
                            -moz-appearance: textfield;
                            -webkit-appearance: none;
                            @media (max-width: 900px) {
                                height: 46px;
                            }
                            @media (max-width: $md6+px) {
                                height: 42px;
                            }
                            &.incorrect {
                                border-color: red;
                            }
                        }
                    }
                }
                .sendBtn {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    text-align: center;
                    border-radius: 10px;
                    background: #B1FE4B;
                    height: 65px;
                    color: #000;
                    font-weight: 700;
                    cursor: pointer;
                    transition: all .3s;
                    @media (max-width: 900px) {
                        height: 55px;
                    }
                    @media (max-width: $md6+px) {
                        height: 48px;
                    }
                    &:hover {
                        background: #7bad38;
                    }
                }
                .checkWrap {
                    display: grid;
                    grid-template-columns: 20px 1fr;
                    gap: 10px;
                    align-items: center;
                    .check {
                        position: relative;
                        border-radius: 2px;
                        border: 1px solid #383838;
                        background: rgba(217, 217, 217, 0.20);
                        width: 20px;
                        height: 20px;
                        cursor: pointer;
                        &::before {
                            content: '';
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            top: 50%;
                            left: 50%;
                            transform: translateX(-50%) translateY(-50%);
                        }
                        &.active {
                            &::before {
                                background: #B1FE4B;
                            }
                        }
                        &.incorrect {
                            border-color: red;
                        }
                    }
                    p {
                        color: #FFF;
                        font-family: $roboto;
                        font-size: 12px;
                        font-weight: 300;
                        line-height: 150%;
                        letter-spacing: 2px;
                        a {
                            color: #B1FE4B;
                            text-decoration: underline;
                            &:hover {
                                color: rgba($color: #B1FE4B, $alpha: 0.8)
                            }
                        }
                    }
                }
            }
        }
        .colHeader {
            position: relative;
            height: 50px;
            display: flex;
            align-items: center;
            // padding: 0 10px;
            color: #E7E7E7;
            font-family: $roboto;
            font-size: 22px;
            font-style: normal;
            font-weight: 400;
            line-height: 150%; /* 33px */
            letter-spacing: 3.74px;
            // border-bottom: 1px solid #383838;
            @media(max-width: $md4+px) {
                letter-spacing: 2px;
            }
            @media (max-width: 900px) {
                height: auto;
                font-size: 20px;
            }
            @media(max-width: $md6+px) {
                letter-spacing: 1px;
                font-size: 18px;
            }
            .filterInput {
                position: relative;
                font-size: inherit;
                font-family: inherit;
                letter-spacing: inherit;
                font-weight: inherit;
                background: transparent;
                color: #fff;
                width: calc(100% - 50px);
                &::placeholder {
                    color: #E7E7E7;
                }
            }
            .searchIcon {
                position: absolute;
                right: 20px;
                width: 16px;
                height: 16px;
                top: calc(50% - 8px);
            }
        }
        ::-webkit-scrollbar {
            width: 5px;
        }
        ::-webkit-scrollbar-track {
            background-color: transparent;
        }
        ::-webkit-scrollbar-thumb {
            background: #575757;
        }
        .colList {
            position: relative;
            overflow-y: auto;
            height: 600px;
            padding-right: 3px;
            .listItem {
                padding: 0 10px;
                cursor: pointer;
                display: flex;
                align-items: center;
                gap: 12px;
                transition: all .1s;
                height: 50px;
                border-bottom: 1px solid #383838;
                &:hover {
                    background: rgba($color: #fff, $alpha: 0.1);
                }
                &.active {
                    background: #B1FE4B;
                    color: #000;
                }
            }
        }
        .successPopup {
            pointer-events: none;
            opacity: 0;
            transition: all .3s;
            position: absolute;
            z-index: 99999;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            flex-direction: column;
            gap: 20px;
            padding: 0 15px;
            &.popupActive {
                pointer-events: visible;
                opacity: 1;
                .successPopupImg {
                    &::before {
                        transform: scale(1)!important;
                    }
                }
            }
            &Text {
                font-size: 14px!important;
                line-height: 130%;
                @media(max-width: 700px) {
                    max-width: 350px;
                }
            }
            &Img {
                position: relative;
                width: 60px;
                height: 60px;
                display: flex;
                justify-content: center;
                align-items: center;
                @media(max-width: $md6+px) {
                    width: 40px;
                    height: 40px;
                }
                svg {
                    transition: all .3s;
                    @media(max-width: $md6+px) {
                        transform: scale(0.7);
                    }
                }
                &::before {
                    content: '';
                    position: absolute;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    border-radius: 100px;
                    border: 4px solid #B1FE4B;
                    transform: scale(1.5);
                    transition: all .3s;
                    @media(max-width: $md6+px) {
                        border-width: 3px;
                    }
                }
            }
        }
    }
}

.currIcon {
    width: 30px;
    @media (max-width: 900px) {
        width: 20px;
    }
}

.currTitle {
    font-size: 16px;
    line-height: 150%;
    font-weight: 400;
}

.incorrect {
    border-color: red;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}
input[type=number]{
    -moz-appearance: textfield;
}

.rateErrorMessage {
    font-size: 20px;
    font-weight: 500;
    padding: 20px;   
    line-height: 140%;
    text-align: center;
}

.mobCurrSelect {
    background: #000;
    color: #fff;
    // width: 100%;
    // width: 400px!important;
    position: absolute!important;
    left: 0;
    top: 0;
    width: 100%;
}

.mobCurrDropdown {
    width: 100%;
    height: 40px;
    align-items: center;
    font-size: 16px;
}

:global(.p-dropdown-panel) {
    background: rgba(#000, 0.9);
}
:global(.p-dropdown-filter) {
    background: transparent;
    border: 1px solid #383838;
    border-radius: 10px;
    height: 36px;
    font-size: 16px;
    padding: 0 40px 0 15px;
    color: #fff;
    &::placeholder {
        color: #fff;
    }
}
:global(.p-dropdown-filter-icon) {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
}
:global(.p-dropdown-items) {
    padding: 10px 0;
}
:global(.p-dropdown-item) {
    font-size: 16px;
    padding: 8px 5px;
}
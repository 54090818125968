@import '../../../styles/var';

.observe {
    position: relative;
    .content {
        max-width: 525px;
        @media (max-width: $md4+px) {
            max-width: 100%;
        }
        .text {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .btn {
            margin-top: 50px;
        }
    }
    .images {
        position: absolute;
        width: 532px;
        height: 401px;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        @media (max-width: $md3+px) {
            transform: translateY(-50%) scale(0.7);
            right: -80px;
        }
        @media (max-width: $md4+px) {
            transform: none;
            right: unset;
            top: unset;
            position: relative;
            margin: 60px auto 0 auto;
        }
        @media (max-width: 600px) {
            width: 339px;
            height: 256px;
            margin-top: 50px;
        }
        .image {
            position: absolute;
            width: 280px;
            will-change: transform;
            @media(max-width: $md3+px) {
                transform: none!important;
            }
            @media (max-width: 600px) {
                width: 178px;
            }
            &_01 {
                bottom: 0;
                left: 0;
                transform: translateX(-50px) translateY(50px);
            }
            &_02 {
                top: 0;
                right: 0;
                transform: translateX(50px) translateY(-50px);
            }
        }
    }
}
@import '../../../styles/var';

.assetTypes {
    position: relative;
    @media(max-width: 600px) {
        padding-bottom: 30px;
    }
    .title {

    }
    .content {
        position: relative;
        .eye {
            position: relative;
            z-index: 2;
            margin: 200px auto 0 auto;
            width: 662px;
            @media(max-width: $md1+px) {
                width: 550px;
                margin-top: 170px;
            }
            @media(max-width: $md4+px) {
                width: 512px;
                margin-top: 85px;
            }
            @media(max-width: 600px) {
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                margin-top: 0;
                top: -10px;
                opacity: 0.5;
            }
            img {
                width: 100%;
            }
        }
        .cards {
            position: absolute;
            z-index: 10;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            @media(max-width: 600px) {
                position: relative;
                left: unset;
                top: unset;
                margin-top: 70px;
                display: flex;
                flex-direction: column;
                gap: 70px;
            }
            .card {
                position: absolute;
                text-align: center;
                width: 266px;
                @media(max-width: 800px) {
                    width: 220px;
                }
                @media(max-width: 600px) {
                    position: relative;
                    width: 266px!important;
                    left: unset!important;
                    right: unset!important;
                    top: unset!important;
                    bottom: unset!important;
                    transform: none!important;
                    margin: 0 auto!important;
                }
                &_01 {
                    left: 0;
                    right: 0;
                    margin: 0 auto;
                    top: -107px;
                    @media(max-width: $md1+px) {
                        top: -80px;
                    }
                    @media(max-width: $md4+px) {
                        top: -20px;
                    }
                    .line {
                        left: 0;
                        right: 0;
                        margin: 0 auto;
                        // width: 100%;
                        width: 0;
                        &::after {
                            // left: 0;
                            // right: 0;
                            // margin: 0 auto;
                            left: 50%;
                            transform: translateY(-50%) translateX(-50%)!important;
                            @media(max-width: 600px) {
                                transform: translateY(-50%)!important;
                            }
                        }
                    }
                }
                &_02 {
                    left: 0;
                    top: 100px;
                    @media(max-width: $md1+px) {
                        top: 110px;
                    }
                    @media(max-width: $md4+px) {
                        top: 169px;
                    }
                    .line {
                        right: 0;
                        // width: 100%;
                        &::after {
                            left: 0;
                        }
                    }
                }
                &_03 {
                    right: 0;
                    top: 100px;
                    @media(max-width: $md1+px) {
                        top: 110px;
                    }
                    @media(max-width: $md4+px) {
                        top: 169px;
                    }
                    .line {
                        left: 0;
                        // width: 100%;
                        &::after {
                            right: 0;
                        }
                    }
                }
                &_04 {
                    left: 130px;
                    bottom: 168px;
                    width: 147px;
                    @media(max-width: $md1+px) {
                        bottom: 140px;
                    }
                    @media(max-width: $md4+px) {
                        bottom: 118px;
                    }
                    @media(max-width: 800px) {
                        width: 120px;
                        left: 60px;
                    }
                    .line {
                        right: 0;
                        // width: 100%;
                        &::after {
                            left: 0;
                        }
                    }
                }
                &_05 {
                    right: 130px;
                    bottom: 168px;
                    width: 147px;
                    @media(max-width: $md1+px) {
                        bottom: 140px;
                    }
                    @media(max-width: $md4+px) {
                        bottom: 118px;
                    }
                    @media(max-width: 800px) {
                        width: 120px;
                        right: 60px;
                    }
                    .line {
                        left: 0;
                        // width: 100%;
                        &::after {
                            right: 0;
                        }
                    }
                }
                .text {
                    position: relative;
                    z-index: 7;
                }
                .line {
                    position: absolute;
                    z-index: 6;
                    height: 4px;
                    bottom: -31px;
                    width: 0;
                    @media(max-width: $md3+px) {
                        width: 100%;
                    }
                    @media(max-width: $md4+px) {
                        bottom: -24px;
                    }
                    @media(max-width: 600px) {
                        height: 3px;
                        bottom: -20px;
                    }
                    &::before, &::after {
                        content: '';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        background: #B1FE4B;
                    }
                    &::before {
                        width: 100%;
                        height: 100%;
                        border-radius: 0px;
                        filter: blur(4px) drop-shadow(0px 0px 21px #B1FE4B);
                        left: 0;
                    }
                    &::after {
                        height: 6px;
                        width: 57px;
                        filter: blur(7px) drop-shadow(0px 0px 9px #B1FE4B) drop-shadow(0px 0px 20px #B1FE4B);
                        @media(max-width: 600px) {
                            left: 0;
                            right: 0;
                            margin: 0 auto;
                            height: 4px;
                        }
                    }
                }
            }
        }
    }
}
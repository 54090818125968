@import '../../../styles/var';

.followRules {
    position: relative;
    .title {

    }
    .content {
        display: grid;
        grid-template-columns: repeat(2, 525px);
        gap: 60px;
        justify-content: space-between;
        @media (max-width: $md3+px) {
            grid-template-columns: repeat(2, 1fr);
        }
        @media (max-width: 800px) {
            display: flex;
            flex-direction: column;
            gap: 15px;
        }
        .col {
            display: flex;
            flex-direction: column;
            gap: 15px;
            // padding-left: 30px;
            li {
                list-style-type: disc;
                list-style-position: inside;
            }
        }
    }
}
@import '../../../styles/var';

.symbolsBg {
    position: fixed;
    z-index: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    pointer-events: none;
    opacity: .06;
    @media (max-width: $md6+px) {
        display: none;
    }
    p {
        line-height: 1;
        will-change: color, text-shadow;
    }
    span {
        display: block;
        width: 2vmax; 
        height: 2vmax; 
        font-size: 2vmax; 
        color: #9bff9b11;
        text-align: center;
        font-family: "Helvetica Neue", Helvetica, sans-serif;
        will-change: color, text-shadow;
    }
}
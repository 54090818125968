@import '../../../styles/var';

.underBanner {
    position: relative;
    z-index: 10;
    margin-top: 433px;
    height: 445px;
    @media(max-width: $md4+px) {
        margin-top: 212px;
    }
    @media(max-width: $md6+px) {
        margin-top: 223px;
    }
    .card {
        position: absolute;
        text-align: center;
        &_01 {
            top: 0;
            left: 0;
            .line {
                // height: 225px;
                height: 0;
                top: -225px;
                @media(max-width: $md3+px) {
                    height: 225px;
                }
                @media(max-width: $md4+px) {
                    height: 120px;
                    top: -120px;
                }
                @media(max-width: $md6+px) {
                    height: 156px;
                    top: -156px;
                }
            }
        }
        &_02 {
            top: 234px;
            left: 270px;
            @media(max-width: $md3+px) {
                left: 200px;
            }
            @media(max-width: $md4+px) {
                top: 290px;
                left: 120px;
            }
            @media(max-width: 600px) {
                left: 60px;
            }
            @media(max-width: $md6+px) {
                top: 320px;
                left: 40px;
            }
            .line {
                top: -397px;
                height: 0;
                @media(max-width: $md3+px) {
                    height: 397px;
                }
                @media(max-width: $md4+px) {
                    height: 180px;
                    top: -180px;
                }
                @media(max-width: $md6+px) {
                    height: 156px;
                    top: -156px;
                }
            }
        }
        &_03 {
            top: 13px;
            left: 0;
            right: 0;
            @media(max-width: $md4+px) {
                top: 190px;
            }
            @media(max-width: 800px) {
                top: 120px;
            }
            @media(max-width: $md6+px) {
                top: 90px;
            }
            .line {
                top: -332px;
                height: 0;
                @media(max-width: $md3+px) {
                    height: 332px;
                }
                @media(max-width: $md4+px) {
                    height: 300px;
                    top: -300px;
                }
                @media(max-width: 800px) {
                    height: 200px;
                    top: -200px;
                }
                @media(max-width: $md6+px) {
                    height: 200px;
                    top: -200px;
                }
            }
        }
        &_04 {
            right: 270px;
            top: 158px;
            @media(max-width: $md3+px) {
                right: 200px;
            }
            @media(max-width: $md4+px) {
                top: -8px;
                right: 120px;
            }
            @media(max-width: $md6+px) {
                top: -45px;
                right: 20px;
            }
            .line {
                top: -397px;
                height: 0;
                @media(max-width: $md3+px) {
                    height: 397px;
                }
                @media(max-width: $md4+px) {
                    height: 120px;
                    top: -120px;
                }
                @media(max-width: $md6+px) {
                    height: 104px;
                    top: -104px;
                }
            }
        }
        &_05 {
            top: 145px;
            right: 0;
            @media(max-width: $md4+px) {
                top: 120px;                
            }
            @media(max-width: $md6+px) {
                top: 270px;
                right: 70px;
            }
            .line {
                top: -266px;
                @media(max-width: $md3+px) {
                    height: 266px;
                }
                @media(max-width: $md4+px) {
                    height: 200px;
                    top: -200px;
                }
                @media(max-width: $md6+px) {
                    height: 100px;
                    top: -100px;
                }
            }
        }
        .line {
            position: absolute;
            left: 50%;
            transform: translateY(-35px) translateX(-50%);
            width: 4px;
            will-change: transform height;
            @media(max-width: $md4+px) {
                transform: translateY(-20px) translateX(-50%);
            }
            @media(max-width: 600px) {
                width: 3px;
            }
            @media(max-width: $md6+px) {
            
            }
            &::before, &::after {
                content: '';
                position: absolute;
                left: 50%;
                transform: translateX(-50%);
                background: #B1FE4B;
            }
            &::before {
                width: 100%;
                height: 100%;
                border-radius: 0px;
                filter: blur(4px) drop-shadow(0px 0px 21px #B1FE4B);
                top: 0;
            }
            &::after {
                width: 6px;
                height: 57px;
                filter: blur(7px) drop-shadow(0px 0px 9px #B1FE4B) drop-shadow(0px 0px 20px #B1FE4B);
                bottom: 0;
                @media(max-width: 600px) {
                    width: 4px;
                    height: 40px;
                }
            }
        }
        .text {
            @media(max-width: 800px) {
                max-width: 100px;
                margin: 0 auto;
            }
            @media(max-width: $md6+px) {
                max-width: unset;
            }
        }
    }
    .btn {
        position: absolute;
        bottom: 0;
        width: 100%;
        text-align: center;
    }
}
.loader {
  position: fixed;
  z-index: 999999;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #000;
  opacity: 1;
  transition: all 1s;
  display: flex;
  align-items: center;
  justify-content: center;
  &.hide {
    opacity: 0;
    pointer-events: none;
    .logo {
      transform: scale(1.5);
    }
  }
  .logo {
    width: 99px;
    transition: all 1s;
  }
}

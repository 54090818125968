@import "../../../styles/var";

.footerWrap {
    position: relative;
    z-index: 5;
    &::before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        width: 1520px;
        height: 790px;
        background-image: url('../../../assets/img/Footer/bg.png');
        background-repeat: no-repeat;
        background-position: bottom center;
        background-size: cover;
        opacity: 0.7;
        @media(max-width: $md6+px) {
            opacity: 0.5;
        }
    }
    .image_01 {
        position: absolute;
        z-index: 0;
        left: 0;
        bottom: 500px;
        width: 348px;
        @media(max-width: $md3+px) {
            display: none;
        }
    }
    .image_02 {
        position: absolute;
        z-index: 0;
        right: 0;
        top: -430px;
        right: 20px;
        width: 496px;
        @media(max-width: $md2+px) {
            width: 400px;
            top: -350px;
        }
        @media(max-width: $md3+px) {
            width: 250px;
            top: -230px;
        }
        @media(max-width: $md4+px) {
        }
        @media(max-width: 600px) {
            width: 120px;
            top: -110px;
        }
    }
}

.footer {
    position: relative;
    z-index: 10;
    border-top: 1px solid #fff;
    font-weight: 300;
    font-size: 18px;
    line-height: 27px;
    color: #fff;
    @media (max-width: $md6+px) {
        font-size: 14px;
        line-height: 21px;
        margin-top: 60px;
    }
    .content {
        padding-top: 26px;
        padding-bottom: 29px;
        position: relative;
        z-index: 2;
        overflow: hidden;
        @media (max-width: $md5+px) {
            padding-top: 15px;
            padding-bottom: 22px;
        }
        .docs {
            position: relative;
            z-index: 2;
            display: flex;
            gap: 60px;
            margin-top: 20px;
            & > a {
                color: #fff;
                cursor: pointer;
                transition: all 0.3s;
                &:hover {
                    color: #B1FE4B;
                }
            }
            @media (max-width: $md4+px) {
                flex-direction: column;
                gap: 10px;
            }
        }
        .header {
            position: relative;
            z-index: 2;
            display: flex;
            justify-content: space-between;
            @media (max-width: $md5+px) {
                display: block;
            }
            .left {
                display: flex;
                align-items: flex-end;
                gap: 30px;
                @media (max-width: $md5+px) {
                    justify-content: space-between;
                }
                &Text {
                    cursor: pointer;
                    display: flex;
                    gap: 30px;
                    &-ru {
                        flex-direction: column;
                        gap: 5px;
                        @media (max-width: $md5+px) {
                            text-align: right;
                        }
                        @media (max-width: $md6+px) {
                            font-size: 12px !important;
                        }
                    }
                }
                .logo {
                    margin-right: 60px;
                    width: 67px;
                    @media (max-width: $md3+px) {
                        width: 50px;
                    }
                    @media (max-width: $md6+px) {
                        margin-right: 20px;
                        width: 40px;
                    }
                    img {
                        width: 100%;
                    }
                }
            }
            .right {
                display: flex;
                gap: 30px;
                align-items: center;
                @media (max-width: $md5+px) {
                    margin-top: 33px;
                    justify-content: flex-start;
                }
                svg {
                    cursor: pointer;
                    path {
                        transition: all 0.3s;
                    }
                    &:hover {
                        path {
                            fill: #B1FE4B;
                        }
                    }
                }
            }
        }
        .info {
            position: relative;
            z-index: 2;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 6px;
            font-weight: 300;
            @media (max-width: $md5+px) {
                margin-top: 32px;
            }
            .address {
                @media (max-width: $md5+px) {
                    margin-top: 0;
                }
            }
        }
        .under {
            position: relative;
            z-index: 2;
            margin-top: 20px;
            display: flex;
            gap: 60px;
            @media (max-width: $md4+px) {
                flex-direction: column;
                gap: 0px;
            }
            @media (max-width: $md5+px) {
                margin-top: 32px;
            }
            .address {
                @media (max-width: $md5+px) {
                    margin-top: 0;
                }
            }
        }
    }
}

.loader {
    position: absolute;
    z-index: 9999;
    left: calc(50% - 24px);
    top: calc(50% - 24px);
    width: 48px;
    height: 48px;
    border-radius: 50%;
    display: inline-block;
    border-top: 4px solid #FFF;
    border-right: 4px solid transparent;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}
.loader::after {
    content: '';  
    box-sizing: border-box;
    position: absolute;
    left: 0;
    top: 0;
    width: 48px;
    height: 48px;
    border-radius: 50%;
    border-bottom: 4px solid #B1FE4B;
    border-left: 4px solid transparent;
}
@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
} 


.searchLoader {
    position: absolute;
    z-index: 9999;
    top: calc(50% - 24px);
    left: calc(50% - 24px);
    width: 48px;
    height: 48px;
    display: block;
    margin: 20px auto;
    border: 3px solid #B1FE4B;
    border-radius: 50%;
    box-sizing: border-box;
    animation: animSearchloader 2s linear infinite;
}
.searchLoader::after {
    content: '';  
    box-sizing: border-box;
    width: 6px;
    height: 24px;
    background: #B1FE4B;
    transform: rotate(-45deg);
    position: absolute;
    bottom: -20px;
    left: 46px;
}
  
@keyframes animSearchloader {
    0% {
        transform: translate(-10px, -10px);
    }
    25% {
        transform: translate(-10px, 10px);
    }
    50% {
        transform: translate(10px, 10px);
    }
    75% {
        transform: translate(10px, -10px);
    }
    100% {
        transform: translate(-10px, -10px);
    }
}
      